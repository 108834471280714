import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import TopBrandsSection from '../components/sections/TopBrandsSection';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import BenefitsSection from '../components/sections/BenefitsSection';
import WidgetMockupSection from '../components/sections/WidgetMockupSection';
import HomeHeroHeader from '../components/sections/HomeHeroHeader';
import NaturesBakerySection from '../components/clients/NaturesBakerySection';
import UsWaterSystemsSection from '../components/clients/UsWaterSystemsSection';
import ContactSalesSection from '../components/sections/ContactSalesSection';

const IndexPage = () => (
  <Layout>
    <Seo title="Subscription Commerce Solutions" />

    <HomeHeroHeader />

    <WidgetMockupSection />

    <BenefitsSection />

    <UsWaterSystemsSection />
    <NaturesBakerySection />

    <TopBrandsSection containerClassName="max-w-screen-lg" showOneLineOnly showCards={false} />

    <ContactSalesSection />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default IndexPage;
