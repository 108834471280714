import React from 'react';

const HeroGraphicLg = ({ className }) => (
  <svg className={className} width="668" height="527" viewBox="0 0 668 527" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M433.781 234.508L491.92 292.635" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M404.71 234.508C420.765 234.508 433.779 221.496 433.779 205.445C433.779 189.393 420.765 176.381 404.71 176.381C388.655 176.381 375.641 189.393 375.641 205.445C375.641 221.496 388.655 234.508 404.71 234.508Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M520.991 350.762C537.046 350.762 550.061 337.75 550.061 321.699C550.061 305.647 537.046 292.635 520.991 292.635C504.937 292.635 491.922 305.647 491.922 321.699C491.922 337.75 504.937 350.762 520.991 350.762Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M637.272 60.127C653.327 60.127 666.342 47.1149 666.342 31.0635C666.342 15.0122 653.327 2 637.272 2C621.218 2 608.203 15.0122 608.203 31.0635C608.203 47.1149 621.218 60.127 637.272 60.127Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M637.272 234.508C653.327 234.508 666.342 221.496 666.342 205.445C666.342 189.393 653.327 176.381 637.272 176.381C621.218 176.381 608.203 189.393 608.203 205.445C608.203 221.496 621.218 234.508 637.272 234.508Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M462.844 205.445H579.121" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M172.163 234.508C188.218 234.508 201.232 221.496 201.232 205.445C201.232 189.393 188.218 176.381 172.163 176.381C156.109 176.381 143.094 189.393 143.094 205.445C143.094 221.496 156.109 234.508 172.163 234.508Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M230.297 205.445H346.574" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M0 205.445H116.277" stroke="url(#paint0_linear_593_350)" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M637.266 89.1906V147.318" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M520.991 525.143C537.046 525.143 550.061 512.131 550.061 496.08C550.061 480.029 537.046 467.016 520.991 467.016C504.937 467.016 491.922 480.029 491.922 496.08C491.922 512.131 504.937 525.143 520.991 525.143Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M520.984 379.826V437.953" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <defs>
      <linearGradient
        id="paint0_linear_593_350"
        x1="115.572"
        y1="205.522"
        x2="-1.11323e-07"
        y2="205.521"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const HeroGraphicMd = ({ className }) => (
  <svg className={className} width="467" height="369" viewBox="0 0 467 369" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M302.699 164.248L343.269 204.81" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M282.414 164.248C293.617 164.248 302.699 155.168 302.699 143.967C302.699 132.766 293.617 123.686 282.414 123.686C271.211 123.686 262.129 132.766 262.129 143.967C262.129 155.168 271.211 164.248 282.414 164.248Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M363.555 245.372C374.758 245.372 383.84 236.292 383.84 225.091C383.84 213.89 374.758 204.81 363.555 204.81C352.351 204.81 343.27 213.89 343.27 225.091C343.27 236.292 352.351 245.372 363.555 245.372Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M444.699 42.562C455.902 42.562 464.984 33.4819 464.984 22.281C464.984 11.0801 455.902 2 444.699 2C433.496 2 424.414 11.0801 424.414 22.281C424.414 33.4819 433.496 42.562 444.699 42.562Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M444.699 164.248C455.902 164.248 464.984 155.168 464.984 143.967C464.984 132.766 455.902 123.686 444.699 123.686C433.496 123.686 424.414 132.766 424.414 143.967C424.414 155.168 433.496 164.248 444.699 164.248Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M322.98 143.967H404.121" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M120.137 164.248C131.34 164.248 140.422 155.168 140.422 143.967C140.422 132.766 131.34 123.686 120.137 123.686C108.933 123.686 99.8516 132.766 99.8516 143.967C99.8516 155.168 108.933 164.248 120.137 164.248Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M160.703 143.967H241.843" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M0 143.967H81.1402" stroke="url(#paint0_linear_593_363)" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M444.695 62.843V103.405" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M363.555 367.058C374.758 367.058 383.84 357.978 383.84 346.777C383.84 335.576 374.758 326.496 363.555 326.496C352.351 326.496 343.27 335.576 343.27 346.777C343.27 357.978 352.351 367.058 363.555 367.058Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M363.551 265.653V306.215" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <defs>
      <linearGradient
        id="paint0_linear_593_363"
        x1="80.6479"
        y1="144.044"
        x2="-7.777e-08"
        y2="144.044"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const HeroGraphicSm = ({ className }) => (
  <svg className={className} width="293" height="233" viewBox="0 0 293 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M189.668 103.621L215.089 129.037" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M176.96 103.621C183.98 103.621 189.671 97.9312 189.671 90.9128C189.671 83.8944 183.98 78.2049 176.96 78.2049C169.941 78.2049 164.25 83.8944 164.25 90.9128C164.25 97.9312 169.941 103.621 176.96 103.621Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M227.8 154.452C234.82 154.452 240.511 148.763 240.511 141.745C240.511 134.726 234.82 129.037 227.8 129.037C220.781 129.037 215.09 134.726 215.09 141.745C215.09 148.763 220.781 154.452 227.8 154.452Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M278.644 27.3731C285.664 27.3731 291.355 21.6836 291.355 14.6652C291.355 7.64681 285.664 1.95728 278.644 1.95728C271.624 1.95728 265.934 7.64681 265.934 14.6652C265.934 21.6836 271.624 27.3731 278.644 27.3731Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M278.644 103.621C285.664 103.621 291.355 97.9312 291.355 90.9128C291.355 83.8944 285.664 78.2049 278.644 78.2049C271.624 78.2049 265.934 83.8944 265.934 90.9128C265.934 97.9312 271.624 103.621 278.644 103.621Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M202.379 90.9128H253.221" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M75.2769 103.621C82.2967 103.621 87.9874 97.9312 87.9874 90.9128C87.9874 83.8944 82.2967 78.2049 75.2769 78.2049C68.2571 78.2049 62.5664 83.8944 62.5664 90.9128C62.5664 97.9312 68.2571 103.621 75.2769 103.621Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M100.695 90.9128H151.537" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M0 90.9128H50.8419" stroke="url(#paint0_linear_593_376)" strokeWidth="3" strokeMiterlimit="10" />
    <path opacity="0.6" d="M278.645 40.0811V65.4969" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <path
      d="M227.8 230.7C234.82 230.7 240.511 225.011 240.511 217.992C240.511 210.974 234.82 205.284 227.8 205.284C220.781 205.284 215.09 210.974 215.09 217.992C215.09 225.011 220.781 230.7 227.8 230.7Z"
      stroke="#004630"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path opacity="0.6" d="M227.797 167.16V192.576" stroke="white" strokeWidth="3" strokeMiterlimit="10" />
    <defs>
      <linearGradient
        id="paint0_linear_593_376"
        x1="50.5334"
        y1="90.9898"
        x2="-4.87616e-08"
        y2="90.9897"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const HeroGraphic = ({ className = '', size = 'lg' }) => (
  <>
    {size === 'lg' && <HeroGraphicLg className={className} />}
    {size === 'md' && <HeroGraphicMd className={className} />}
    {size === 'sm' && <HeroGraphicSm className={className} />}
  </>
);

export default HeroGraphic;
