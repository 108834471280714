import React from 'react';
import InteractRegular from '../icons/InteractRegular';
import CommunicateRegular from '../icons/CommunicateRegular';

const BenefitCard = ({ icon, title, description }) => {
  const Icon = {
    interact: InteractRegular,
    communicate: CommunicateRegular,
  }[icon];

  return (
    <div className="rounded overflow-hidden shadow p-12 text-center">
      <dt>
        <Icon className="mb-12 inline-block" />
        <p className="mb-8 text-3xl ">{title}</p>
      </dt>
      <dd className="text-base text-sp-text-dark">{description}</dd>
    </div>
  );
};

const BenefitsSection = () => (
  <section>
    <div className="max-w-xl lg:max-w-[1380px] mx-auto px-4 sm:px-6 lg:px-8 text-sp-black-dark">
      <div className="flex justify-center">
        <h2 className="max-w-3xl text-4xl font-bold text-center text-black">
          Customizable Subscription Programs Tailored to Your Business Goals
        </h2>
      </div>
      <dl className="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8 mt-16 mb-20 text-sp-text">
        <BenefitCard
          icon="interact"
          title="Integrated"
          description="Our native integrations for Magento, Salesforce Commerce Cloud B2C, and others work seamlessly with your current
        stack. Recurring orders are created directly in your e-commerce platform."
        />
        <BenefitCard
          icon="communicate"
          title="Customizable"
          description="Our subscription platform is designed to be customized. We collaborate closely with merchants to create a
          customer-focused subscription experience that supports your business goals."
        />
        <BenefitCard
          icon="communicate"
          title="Supported"
          description="Our team is here to help you get the most out of our platform. Support is available by phone, email, and ticketing
          system. Our clients rave about our support and you will too."
        />
      </dl>
    </div>
  </section>
);

export default BenefitsSection;
