import React from 'react';

import Container from '../Container';
import WidgetMockupFull from '../WidgetMockupFull';

const WidgetMockupSection = () => (
  <section>
    <Container className="py-16 sm:py-[8rem]">
      <div className="flex justify-center items-center">
        <div className="mx-[2rem] md:mx-[4rem] lg:mx-[5rem]">
          <WidgetMockupFull className="max-w-[900px]" />
        </div>
      </div>
    </Container>
  </section>
);

export default WidgetMockupSection;
