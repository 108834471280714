import React from 'react';
import { navigate } from 'gatsby';

import ArrowRight from '../svg/ArrowRight';
import useContactForm from '../../hooks/useContactForm';
import TextField from '../TextField';
import RecaptchaField from '../RecaptchaField';

const ContactSalesSection = () => {
  const { data, handleChange, handleRecaptcha, handleSubmit } = useContactForm(() => {
    navigate('/thank-you-contact-sales');
  });

  return (
    <section>
      <div className="flex items-center py-20">
        <div className="w-full md:max-w-lg mx-auto px-6 md:px-0">
          <h2 className="text-4xl text-center mb-8">Contact sales</h2>
          <p className="text-lg text-center mb-10">
            Ready to build better relationships with your best customers? Just fill out your information here or give us a call at
            1-888-918-3569.
          </p>
          <form
            onSubmit={handleSubmit}
            method="POST"
            name="contact-sales"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            v
          >
            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
              <TextField
                value={data['bot-field']}
                onChange={handleChange}
                name="bot-field"
                label="Bot Field"
                className="hidden"
              />
              <TextField value={data['first-name']} onChange={handleChange} name="first-name" label="First name" />
              <TextField value={data['last-name']} onChange={handleChange} name="last-name" label="Last name" />
              <TextField
                type="email"
                value={data.email}
                onChange={handleChange}
                name="email"
                label="Email"
                className="sm:col-span-2"
              />
              <TextField value={data.message} onChange={handleChange} name="message" label="Message" className="sm:col-span-2" />
              <RecaptchaField handleRecaptcha={handleRecaptcha} className="sm:col-span-2" />
            </div>
            <div className="mt-8 text-center">
              <button type="submit" className="w-auto btn btn-lg btn-sp-green">
                <span className="block">Let&apos;s talk</span>
                <ArrowRight className="ml-5" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSalesSection;
