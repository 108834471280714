import React from 'react';
import { Link } from 'gatsby';

import Container from '../Container';
import ArrowRight from '../svg/ArrowRight';
import HeroGraphic from '../svg/HeroGraphic';

const HomeHeroHeader = ({ className }) => (
  /** Compensate for bottom padding on top nav with pb-7 */
  <header className={`relative bg-sp-primary background-hero sm:pb-7 ${className}`}>
    <Container>
      <div className="flex flex-col lg:flex-row items-center md:px-10 lg:px-0 py-20 lg:py-0">
        <div className="max-w-[48rem] text-center md:text-left mr-auto">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-6xl text-white mb-10 sm:mb-8 px-12 md:px-0">
            Build <span className="text-sp-text-black">Sustainable </span>
            <br />
            Relationships With <br />
            Your Best Customers
          </h1>
          <p className="text-2xl md:text-2xl lg:text-3xl text-white px-5 md:px-0 mb-10 sm:mb-8">
            {/* We keep your recurring orders recurring. */}
            Powered by our flexible and customizable subscription commerce technology.
          </p>
          <div className="flex justify-center md:justify-start">
            <Link className="btn btn-sp-pastel w-auto justify-center md:justify-start" to="/request-demo">
              <span>Book a demo</span> <ArrowRight className="ml-5 mb-[3px]" />
            </Link>
          </div>
        </div>
        <div className="md:mr-11">
          <HeroGraphic className="hidden xl:block w-full lg:my-10" size="lg" />
          <HeroGraphic className="hidden lg:block xl:hidden w-full lg:my-10" size="md" />
          <HeroGraphic className="hidden md:block lg:hidden w-full mt-12" size="lg" />
          <HeroGraphic className="hidden sm:block md:hidden w-full mt-12" size="md" />
          <HeroGraphic className="block sm:hidden w-full mt-12" size="sm" />
        </div>
      </div>
    </Container>
  </header>
);

export default HomeHeroHeader;
