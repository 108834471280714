import React from 'react';

const CommunicateRegular = ({ className }) => (
  <svg className={className} width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.3615 62.458C68.8208 62.458 82.1683 49.1453 82.1683 32.729C82.1683 16.3126 68.8208 3 52.3615 3C35.9022 3 22.5547 16.3126 22.5547 32.729V62.458H52.3615Z"
      stroke="currentColor"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path d="M37.457 32.7289H67.2638" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M37.457 21.5806H67.2638" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M37.457 43.8773H67.2638" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="24.9244" cy="59.605" rx="23.9244" ry="23.0216" fill="#C2EDE0" />
    </g>
  </svg>
);

export default CommunicateRegular;
