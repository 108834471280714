import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import BrowserFrame from './BrowserFrame';

const WidgetMockupFull = () => (
  <BrowserFrame className="max-w-[900px]">
    {/* <img
      className="object-contain "
      src="/static-images/sub_widget_vanilla_almond.png"
      alt="Product Details Page Subscription Widget"
    /> */}
    <StaticImage
      className="object-contain "
      src="../images/widget_mockup_full.png"
      width={900}
      quality={95}
      objectFit="contain"
      alt="Product Details Page Subscription Widget"
    />
  </BrowserFrame>
);

export default WidgetMockupFull;
