import React from 'react';

const InteractRegular = ({ className }) => (
  <svg className={className} width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9034 47.4803L5 62.3448L19.9034 77.2093" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M64.6118 62.3448H16.1758" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M49.7109 47.4804L64.6143 32.616L49.7109 17.7515" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <path d="M5 32.6159H53.4361" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <ellipse cx="55.9713" cy="32.0216" rx="23.9244" ry="23.0216" fill="#C2EDE0" />
    </g>
  </svg>
);

export default InteractRegular;
